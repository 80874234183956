<template>
  <b-modal id="configurar-consulta-modal" hide-footer fade centered hide-header v-model="showModal"
    @hidden="UpdateShowModal(false)">
    <div class="modal-content" style="
        width: 92vw;
        height: 95vh;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      ">
      <div class="modal-body" style="overflow-y: auto;">
        <div class="row">
          <div class="row col-12">
            <div class="col-lg-6 mb-4">
              <label for="colsp" class="form-label">Largura</label>
              <Slider v-model="colspan" :max='painel_consulta?.tipo_grafico == 1 ? 3 : 12' :min='3'
                :tooltipPosition="'bottom'" />
            </div>

            <div class="col-lg-6  mb-3 ">

              <label class="form-label" for="icon">Icone</label>
              <div class="d-flex align-items-center gap-2">
                <input v-model="icones" id='icon' class="form-control" type="text">
                <i :class="icones + ' fs-18'"></i>
              </div>

            </div>
            <div class="col-lg-6 mb-4">
              <label for="rowspan" class="form-label">Altura</label>
              <Slider v-model="rowspan"
                :max="painel_consulta?.tipo_grafico === 1 && values.length > 1 ? 4 : (painel_consulta?.tipo_grafico === 1 ? 3 : 12)"
                :min="painel_consulta?.tipo_grafico === 1 && values.length > 1 ? 4 : 3" :tooltipPosition="'bottom'" />
            </div>
            <div class="col-lg-3 d-flex  flex-column align-content-center mb-3 ">
              <label class="form-label" for="corborda">Cor da Borda</label>
              <input v-model="cor_borda" id='corborda' style="width: 100%; height: 100%; " type="color">
            </div>
            <div class="col-lg-3 mb-2">
              <label for="orderSelect" class="form-label">Ordem</label>
              <input v-model="order" type="number" step="1" max="10" min="1" class="form-control" id="orderSelect"
                required>
              <div class="invalid-feedback">insira um Número de 1 a 12</div>
            </div>
          </div>
          <div class="col-11 ">
            <eixos :newValores="values" :newLabels="labels" :addValor="addValores" :addLabels="addLabels"
              :removeValor="removeValor" :removeLabels="removeLabel" :todosInputsLabels="todosInputsLabels"
              :todosInputsValores="todosInputsValores" :painel_consulta="painel_consulta" :lista_agregador="agregador"
              :lista_agregador_total="agregadorTotal" :lista_posicao_coluna="posicao_coluna"
              :grafico_combo="grafico_combo">
            </eixos>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-light row p-2 m-0 justify-content-between gap-2">
        <div class="p-0 m-0 me-auto  row col-12 col-sm-auto">
          <b-button variant="danger" id="preview-Chart" v-b-modal.preview-chart @click="seePreview()"
            :disabled="!isFormValid()" class="col-12 ">
            Preview
          </b-button>
        </div>

        <div class="row d-flex p-0 m-0 col-12 col-sm-auto ml-md-auto justify-content-around ">
          <button data-bs-dismiss="modal" class=" btn btn-secondary  col-12 col-sm-6 ">
            Voltar
          </button>
          <button @click="sendConfigConsulta(colspan, cor_borda, order, rowspan, icones)" :disabled="!isFormValid()"
            class="btn btn-primary col-12 col-sm-6  text-center">Salvar</button>
        </div>
      </div>

    </div>
    <previewComponent :allConfigComponent="preview"></previewComponent>
  </b-modal>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { getValueListById } from '../../helpers/forms/getListaValorById';
import {
  tipoGrafico, limitadorEixo, getGradient, tipoCombo, backgroundColorWithOpacity
} from './funcionalidadeschart'
import previewComponent from './component.vue'
import eixos from './eixos.vue'
import Slider from "@vueform/slider";

export default {
  components: {
    Slider, eixos, previewComponent
  },
  emits: ['updateConfiguracao'],
  props: {
    painel_consulta: {
      type: Object || null
    },
    visible: {
      type: Boolean,
      default: false
    },
    configuracaoAtual: {
      type: Object || null
    },
    UpdateShowModal: {
      type: Function
    },
    lista_campo: {
      type: Object
    },
    id_tabela: {
      type: String
    }
  },
  setup(props, { emit }) {
    const agregadorTotal = ref(null);
    const posicao_coluna = ref(null);
    const agregador = ref(null);
    const grafico_combo = ref(null);

    async function listaAgregadorTotal() {
      await getValueListById('0d53a2b6-b565-4040-88b8-ca6596937a9c', '')
        .then(a => agregadorTotal.value = a)
      await getValueListById('7b28ef48-a007-4e14-84c5-91c343c5cbea', '')
        .then(a => agregador.value = a)
      await getValueListById('ea2f874a-02c9-4ff1-8370-d349638df82e', '')
        .then(a => posicao_coluna.value = a)
      await getValueListById('b70c98bf-116b-4d38-a895-eed4d0d33862', '')
        .then(a => grafico_combo.value = a)
    }

    onMounted(() => {
      if (props.id_tabela == '3172d174-b55e-4993-b863-48d5e92f6663') {
        listaAgregadorTotal()
      }
    });

    //config default
    const showModal = ref(false)
    const colspan = ref(3)
    const cor_borda = ref('');
    const icones = ref('')
    const rowspan = ref(3)
    const config = ref([])
    const type = ref()
    const preview = ref(null)
    const values = ref([])
    const order = ref(0)
    const labels = ref([])
    //config mutate
    const default_l = [{
      nome: null,
      titulo: null,
      paginacao: ''
    }]

    const default_v = [{
      nome: null,
      titulo: null,
      prefixo: null,
      sufixo: null,
      cor: '#899cd9',
      agregador: 2,
      casadec: 2,
      rotulo: false,
      ordem_column: 0,
      grafico_combo: 1,
      datasetparam: null,
      scala: false
    }]

    const default_table = [{
      nome: null,
      titulo: null,
      prefixo: null,
      sufixo: null,
      cor: '#899cd9',
      agregador: 1,
      ordem_column: 1,
      posicao_columns: 1,
      agregador_total: 1,
      posicao: 1,
      casadec: 0,
      type: null
    }]

    const addLabels = () => {
      let newLabel
      labels.value.length == 0 ? newLabel = { nome: '', titulo: '', type: '' } : newLabel = { paginacao: '' }
      labels.value.push(newLabel);
    }

    const addValores = () => {
      if (values.value.length < limitadorEixo.Y(props.painel_consulta.tipo_grafico)) {

        let newValor;
        switch (props.painel_consulta.tipo_grafico) {
          case 6: //tabela
            newValor = {
              nome: null,
              titulo: null,
              prefixo: null,
              sufixo: null,
              agregador: 1,
              cor: '#899cd9',
              ordem_column: values.value.length + 1,
              casadec: 2,
              agregador_total: 1,
              posicao_columns: 1,
              type: null
            }
            break;
          case 1:
            newValor = {
              nome: null,
              titulo: null,
              prefixo: null,
              sufixo: null,
              casadec: 2,
              agregador: 2,
              cor: '#899cd9',
            }
            break;
          default:
            newValor = {
              nome: null,
              titulo: null,
              grafico_combo: 1,
              prefixo: null,
              ordem_column: values.value.length + 1,
              sufixo: null,
              casadec: 2,
              agregador: 2,
              cor: '#899cd9',
              rotulo: false,
              scala: false,
              stack_group: 'Group ' + values.value.length
            }
        }
        values.value.push(newValor);
      }
    }

    const removeValor = (index) => {
      if (values.value.length > 1) {
        values.value.splice(index, 1);
      }
    };

    const removeLabel = (index) => {
      if (labels.value.length > 1) {
        labels.value.splice(index, 1);
      }
    };

    watch(() => props.visible, (newValue) => {
      if (newValue) {
        //colunas ou valores
        values.value.nome = '';
        values.value.titulo = '';
        values.value.agregador = 1;
        values.value.cor = '#899cd9';
        values.value.prefixo = '';
        values.value.sufixo = '';
        values.value.ordem_column = 1;
        values.value.agregador_total = 1;
        values.value.posicao = 1;
        values.value.rotulo = false
        values.value.grafico_combo = 1
        values.value.datasetparam = null
        values.value.scala = false
        values.value.stack_group = 'Group'

        //eixo x 

        colspan.value = props.configuracaoAtual?.colspan || 3;
        rowspan.value = props.configuracaoAtual?.rowspan || 3;
        order.value = props.configuracaoAtual?.ordem_exibicao || 0;
        icones.value = props.configuracaoAtual?.icone || 'mdi mdi-chart-bar';
        cor_borda.value = props.configuracaoAtual?.cor_borda || '#899cd9'
        switch (props.painel_consulta.tipo_grafico) {
          case 1:
            values.value = props.configuracaoAtual?.valor || default_v
            break
          case 6:
            values.value = props.configuracaoAtual?.col || default_table
            break
          default:
            values.value = props.configuracaoAtual?.eixo_y || default_v
            labels.value = props.configuracaoAtual?.eixo_x || default_l
        }
        setTimeout(() => showModal.value = true, 200)
      } else {
        setTimeout(() => showModal.value = false, 700)
      }
    })
    function sendConfigConsulta(colspan, cor_borda, order, rowspan, icone) {
      let ssw
      switch (props.painel_consulta.tipo_grafico) {
        case 1:
          ssw = {
            colspan: colspan,
            rowspan: rowspan,
            icone: icone,
            cor_borda: cor_borda,
            ordem_exibicao: order,
            valor: values.value
          }
          break;
        case 6:
          ssw = {
            colspan: colspan,
            rowspan: rowspan,
            icone: icone,
            cor_borda: cor_borda,
            ordem_exibicao: order,
            col: values.value
          }
          break;
        default:
          ssw = {
            colspan: colspan,
            rowspan: rowspan,
            icone: icone,
            cor_borda: cor_borda,
            ordem_exibicao: order,
            eixo_y: values.value,
            eixo_x: labels.value
          }
      }
      config.value = ssw
      emit('updateConfiguracao', config.value)

    }

    function seePreview(state) {
      const type = props.painel_consulta.tipo_grafico

      const ChartExample = () => {
        const mylabels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', `Maio`, 'Junho', 'Julho']

        const datasets = values.value.map((v) => {
          let dataDefault = []
          for (let i = 0; i < mylabels.length; i++) {
            // Gerar um número aleatório entre -50000 e 50000
            const randomNumber = Math.random() * (500 - 600) + 600;
            // Adicionar o número aleatório ao array
            dataDefault.push(randomNumber);
          }
          return {
            label: v.titulo || v.nome,
            data: dataDefault,
            backgroundColor: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                // This case happens on initial chart load
                return
              }
              return getGradient(ctx, chartArea, v.cor, type);
            },
            borderWidth: .6,
            borderColor: backgroundColorWithOpacity(v.cor, 1, 101),
            borderRadius: 4,
            datalabels: {
              display: function (context) {
                // Define o comprimento máximo permitido para os dados antes de começar a espaçar os labels
                const maxLength = 15; // Você pode ajustar esse valor conforme necessário
                const index = context.dataIndex;
                // Calcula a largura do gap entre os labels com base no comprimento dos dados
                const gap = Math.ceil(context.dataset.data.length / maxLength);
                const datazero = context.dataset.data[index] != 0
                // Retorna true para exibir o label a cada gap
                return context.dataIndex % gap === 0 && v.rotulo && datazero;
              },
              climp: true,
              align: function (context) {
                var index = context.dataIndex;
                var curr = context.dataset.data[index];

                return curr > 0 ? 'end' : 'start'
              },
              anchor: function (context) {
                var index = context.dataIndex;
                var curr = context.dataset.data[index];

                return curr > 0 ? 'end' : 'start'
              },

              color: state == 'light' ? 'black' : 'white',
              formatter: (v, context) => {
                let label = v;
                let prefix = context.dataset.prefix || '';
                let suffix = context.dataset.suffix || '';
                let casadec = context.dataset.casadec;
                let value = label.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec });
                return prefix + value + suffix;
              }
            },
            prefix: v.prefixo ? v.prefixo + ' ' : v.prefixo,
            suffix: v.sufixo ? ' ' + v.sufixo : v.sufixo,
            casadec: v.casadec,
            order: v.ordem_column,
            type: type == 7 ? tipoCombo(v.grafico_combo) : null,
            stack: v.stack_group
          }
        })


        const options = {
          layout: {
            padding: {
              top: 10
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          locale: 'pt-BR',
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  let label = context.parsed.y;
                  let prefix = context.dataset.prefix || '';
                  let suffix = context.dataset.suffix || '';
                  let casadec = context.dataset.casadec;
                  let datasetLabel = context.dataset.label;
                  switch (type) {
                    case 2: // Gráfico de Barra
                      label = context.parsed.y;
                      break;
                    case 3: // Gráfico de Barra Vertical
                      label = context.parsed.x;
                      break;
                    case 4: // Gráfico de Linha
                      label = context.parsed;
                      break;
                    case 5: // Gráfico de Rosca
                      label = context.parsed.y;
                      break;
                    default:
                      label;
                      break;
                  }
                  let value = label.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec });
                  return datasetLabel + ': ' + prefix + value + suffix;
                }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: state == 'light' ? '#494949' : '#C9C4C4',
                  stacked: true
                }
              },
              y: {
                ticks: {
                  color: state == 'light' ? '#494949' : '#C9C4C4',
                }
              }
            }
          }
        }
        if (type === 3) {
          options.indexAxis = 'y';
        }

        let chartPreview = {
          type: tipoGrafico(type),
          rowspan: rowspan.value,
          cor_borda: cor_borda.value,
          id_type: type,
          titulo: props.painel_consulta.titulo,
          colspan: colspan.value,
          icone: icones.value,
          referencia: ref('myChart'),
          mydata: {
            data: {
              labels: mylabels,
              datasets: datasets
            },
            options: options
          }
        }
        return chartPreview
      }

      //CARD EXEMPLO
      const CardExample = () => {
        let dataDefault = [32000.30, 10000]
        const valor = values.value.map((v) => {
          return {
            label: v.titulo || v.nome,
            valor: dataDefault[0],
            agregador: v.agregador,
            backgroundColor: v.cor,
            prefix: v.prefixo ? v.prefixo + ' ' : v.prefixo,
            suffix: v.sufixo ? ' ' + v.sufixo : v.sufixo,
            casadec: v.casadec,
            id_type: type,
            valorAtual: dataDefault[1],
            icone: icones.value,
            rotulos: v.rotulo
          }
        })
        const cardStructure = {
          valores: valor,
          colpan: colspan.value,
          rowspan: rowspan.value,
          id_type: type,
          icone: icones.value,
          cor_borda: cor_borda.value,
          titulo: props.painel_consulta.titulo || props.painel_consulta.nome,
          id: ''
        }
        if (valor.length > 1) {
          const options = {
            responsive: true,
            maintainAspectRatio: true,
            locale: 'pt-BR',
            plugins: {
              tooltip: {
                enabled: true,
              },
              legend: {
                display: false
              }
            },
            rotation: 270,
            cutout: '75%',
          }
          const datasets =
          {
            labels: valor.map(a => a.label),
            datasets: [{
              label: valor.map(a => a.label),
              data: dataDefault,
              backgroundColor:
                function (context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return
                  }
                  let cor =
                    valor.map((a, i) => i > 0 ?
                      backgroundColorWithOpacity(a.backgroundColor, .09) :
                      getGradient(ctx, chartArea, a.backgroundColor, type))
                  return cor
                }
              ,
              borderWidth: 0,
              prefix: valor.map(a => a.prefix),
              suffix: valor.map(a => a.suffix),
              casadec: valor.map(a => a.casadec),
              rotulos: valor.map(a => a.rotulos),
              datalabels: {
                display: (vas) => {
                  let index = vas.dataIndex
                  let boolean = vas.dataset.rotulos[index]
                  return boolean
                },
                align: 'center',
                borderRadius: 12,
                formatter: (_, cont) => {
                  let index = cont.dataIndex
                  let casadec = cont.dataset.casadec[index]
                  let prefix = cont.dataset.prefix[index] || ''
                  let suffix = cont.dataset.suffix[index] || ''
                  let value = cont.dataset.data[index]
                  let teste =
                    (prefix + ' ') +
                    value.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec })
                    + (' ' + suffix)
                  return teste
                },
                anchor: 'start',
                color: state == 'light' ? 'black' : 'white',
                backgroundColor: valor.map((a, i) => i > 0 ? backgroundColorWithOpacity(a.backgroundColor, .07) : backgroundColorWithOpacity(a.backgroundColor, .08)),
              },
              circumference: 180,
            }]
          }

          let d = {
            data: datasets,
            options: options,
            type: 'doughnut',
            ref: ref(null)
          }
          cardStructure.acelerometro = d
        }
        return cardStructure
      }

      //TABELA EXEMPLO
      const TableExample = () => {
        const Nomes_Agregadores = values.value.map(v => v)
        const columns = Nomes_Agregadores
        const dataTableValues = [];

        for (var i = 1; i <= 100; i++) {
          const tempObj = {}
          columns.forEach(colName => {
            tempObj[colName.titulo || colName.nome] = Math.floor(Math.random() * 1000)
          })
          dataTableValues.push(tempObj)
        }

        const dataTable =
        {
          id_type: type,
          titulo: props.painel_consulta.titulo || props.painel_consulta.nome,
          colspan: colspan.value,
          cor_borda: cor_borda.value,
          id: 'tabella',
          icone: icones.value,
          dados: dataTableValues,
          colunas: Nomes_Agregadores
        }
        return dataTable
      }
      //PUXAR PREVIEW
      switch (type) {
        case 1:
          preview.value = CardExample();
          break
        case 6:
          preview.value = TableExample()
          break
        default: preview.value = ChartExample()
      }

    }

    function isFormValid() {
      // Se o tipo de gráfico for 1, verifique apenas os campos de valor
      if (props.painel_consulta !== null && (props.painel_consulta.tipo_grafico === 1 || props.painel_consulta.tipo_grafico === 6)) {
        // Verifique se todos os campos 'nome' em 'values' estão preenchidos
        return values.value.every(value => value.nome !== null && value.nome !== '');
      } else {
        // Para outros tipos de gráfico, verifique se todos os campos 'nome' em 'values' e 'labels' estão preenchidos
        const valuesValid = values.value.every(value => value.nome !== null && value.nome !== '');
        const labelsValid = labels.value ? labels.value.every((label, index) => index == 0 ? label.nome !== null && label.nome !== '' : label.paginacao !== null && label.paginacao !== '') : '';
        return valuesValid && labelsValid;
      }
    }
    const todosInputsValores = computed(() => {

      for (const condition of values.value) {
        if (!condition.nome) {
          return false;
        }
      }
      return true;
    });

    const todosInputsLabels = computed(() => {
      if (labels.value !== null) {
        for (const condition of labels.value) {
          if (!condition.nome && !condition.paginacao) {
            return false;
          }
        }
      }
      return true;
    })
      ;
    return {
      agregadorTotal,
      posicao_coluna,
      agregador,
      grafico_combo,
      isFormValid,
      todosInputsValores,
      todosInputsLabels,
      preview,
      seePreview,
      sendConfigConsulta,
      values,
      addValores,
      removeValor,
      removeLabel,
      addLabels,
      icones,
      showModal,
      labels,
      colspan,
      order,
      cor_borda,
      rowspan,
      type,
    }
  },
};
</script>